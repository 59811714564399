<template>
    <div>
        <div style="margin-top:15px">
            <el-menu default-active="0" background-color="#F7F7F7" mode="horizontal" @select="handleSelect">
                <el-menu-item index="0">待上刊媒体</el-menu-item>
                <el-menu-item index="1">即将上刊媒体</el-menu-item>
                <el-menu-item index="2">已上刊媒体</el-menu-item>
                <el-menu-item index="3">即将下刊媒体</el-menu-item>
                <el-menu-item index="4">已过期未下刊媒体</el-menu-item>
                <el-menu-item index="5">已下刊媒体</el-menu-item>
                <div style="float:left;height:100%;margin:15px">
                    <el-button type="primary" v-if="(searchForm.orderState==='0'||searchForm.orderState==='1')&&showTable" @click="UpdatePublish">批量上刊</el-button>
                    <el-button type="primary" v-if="(searchForm.orderState==='2'||searchForm.orderState==='3'||searchForm.orderState==='4')&&showTable" @click="UpdatePublish">批量下刊</el-button>
                    <JsonExcel  v-show="!showTable" :fields="exportDataStandard" :data="exportData" type="xls" name="空刊点位信息.xls" :fetch="createExportData" :before-finish="finishDownload" worksheet="空刊点位信息">
                      <el-button type="primary" class="el-icon-download"> 导出</el-button>
                    </JsonExcel>
                </div>
                <div style="float:right;height:100%;margin:15px">
                    <el-input placeholder="请输入订单名，点位名" v-model="searchForm.searchInfo" style="width:200px">
                       <el-button slot="append" icon="el-icon-search" @click="Search"></el-button>
                    </el-input>
                </div>
            </el-menu>
        </div>
        <div>
          <el-table :data='OrderListData' size="mini" ref='orderTable' stripe :highlight-current-row='true' max-height='calc(100vh - 195px)' height='calc(100vh - 195px)'>
            <el-table-column v-if="showTable" align="center" type="selection" width="50"></el-table-column>
            <el-table-column align="center" prop="order_name" label="名称"></el-table-column>
            <el-table-column align="center" label="日期">
              <template slot-scope="scope">
                <div v-if="searchForm.orderState==='0'||searchForm.orderState==='1'">
                  {{FormateData(scope.row.order_begin_time,0)}}
                </div>
                <div v-else>
                  {{FormateData(scope.row.order_end_time,0)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="cc" label="点位数">
              <template slot-scope="slot">
                <el-link type="primary" @click="DetailOrder(slot.row)">{{slot.row.cc}}</el-link>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="sp_name" label="监测人员"></el-table-column>
          </el-table>
          <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="total" background :page-size="searchForm.pageSize"></el-pagination>
        </div>


 

          <!--上刊-->
      <el-dialog v-if="dialogVisible" :title="title" :visible.sync="dialogVisible" :width='screenWidth>1400?"60%":"70%"' :append-to-body='true'>
            <el-form :model="publisForm" :rules="rules" ref="publisForm" label-width="100px" size='medium'>
                <el-form-item label="监测人员" >
                    <el-select v-model="publisForm.sp_id" style="width:100%;">
                      <el-option v-for="item in SalePerDataList" :key="item.user_id" :value="item.user_id" :label="item.user_name" ></el-option>
                    </el-select>
                </el-form-item>
                <div v-if="searchForm.orderState==='0'||searchForm.orderState==='1'">
                  <el-form-item label="合作商" >
                    <div class="tool">
                      <el-select v-model="publisForm.pc_id" style="width:100%;">
                        <el-option v-for="item in ProdCompanyDataList" :key="item.pc_id" :value="item.pc_id" :label="item.pc_name" ></el-option>
                      </el-select>
                      <el-button type="primary" icon="el-icon-plus" size="mini" style="margin-left:10px" @click="AddProdCompany"></el-button>
                    </div>

                  </el-form-item>
                  <!-- <el-form-item label="上刊费用" >
                      <el-input v-model="publisForm.price"></el-input>
                  </el-form-item> -->
                  <el-form-item label="备注">
                      <el-input v-model="publisForm.remark"></el-input>
                  </el-form-item>
                  <uploadPic v-if="dialogVisible" :photoList="publisForm.publish_pic_list" :title="'上刊图片'" ref='childRules'/>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="Save">提 交</el-button>
            </span>
      </el-dialog>

        <!--广告制作公司-->
      <el-dialog :title="pctitle" :close-on-click-modal="false" :visible.sync="companyDialogVisible" width='35%' :append-to-body='true'>
         <el-form :model="companyForm" :rules="rules" ref="companyForm" label-width="100px" size='medium'>
            <el-form-item label="公司名称" prop="pc_name">
                <el-input v-model="companyForm.pc_name"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="companyForm.pc_remark"></el-input>
            </el-form-item>
         </el-form>
          <span slot="footer" class="dialog-footer">
                <el-button @click="companyDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Ok">确 定</el-button>
          </span>
      </el-dialog>

        <!--订单详情-->
      <el-dialog v-if="mediaDialogVisible" :title="title" :visible.sync="mediaDialogVisible" :width='screenWidth>1400?"60%":"70%"' :append-to-body='true'>
            <OrderDetail :orderId="OrderDetailId" :showDel="false" :orderName="OrderName"></OrderDetail>
            <span slot="footer" class="dialog-footer">
              <el-button @click="mediaDialogVisible = false">取 消</el-button>
            </span>
      </el-dialog>

    </div>
</template>

<script>
import util from '../../util/util'
import uploadPic from '../../components/UploadPicture.vue'
import OrderDetail from '../../components/media/OrderDetail.vue'
import JsonExcel from 'vue-json-excel'

export default {
  data: function () {
    return {
      screenWidth: 0,
      searchForm: {
        orderState: '0',
        searchInfo: '',
        pageNo: 1,
        pageSize: 18
      },
      total: 0,

      OrderListData: [],
      showTable:true,
      MediaListData: [],
      SalePerDataList: [],

      dialogVisible: false,
      title: '',
      publisForm: {
        order_id: [],
        remark: '',
        sp_id: null,
        pc_id: null,
        price: 0,
        publish_pic_list: []
      },

      mediaDialogVisible: false,
      OrderDetailId:0,
      OrderName:'',

      ProdCompanyDataList: [],
      companyDialogVisible: false,
      pctitle: '',
      companyForm: {
        pc_name: '',
        pc_remark: ''
      },
      rules: {
        pc_name: [{ required: true, message: '请输入公司名称', trigger: 'blur' }]
      },


      exportData:[],
      exportDataStandard:{
        编号:'media_no',
        名称:'media_name',
        点位类型:{
          field: 'media_show_type',
            callback: value => {
              return value===0?'灯箱广告':(value===1?'道闸广告':(value===2?'电梯框架':(value===3?'广告看板':(value===4?'屏蔽门':(value===5?'候车亭广告':(value===6?'车身广告':'其他'))))))
            }
        },
        位置:'media_community_addr'
      }
    }
  },
  components: {
    uploadPic,
    OrderDetail,
    JsonExcel
  },
  computed: {
    FormateData () {
      return function (time, type) {
        return util.FormateDate(time, type)
      }
    }
  },
  created () {
    this.screenWidth = document.body.clientWidth
    this.GetOrderDataList()
  },
  methods: {
    handleSelect (key, keyPath) {
      this.searchForm.orderState = key
      this.showTable=true
      if(key==='5'){
        this.showTable=false
      }
      this.searchForm.pageNo=1
      this.searchForm.searchInfo=''
      this.GetOrderDataList()
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetOrderDataList()
    },
    Search () {
      this.searchForm.pageNo=1
      this.GetOrderDataList()
    },
    GetOrderDataList () {
      util.Get('publish/getApplyOrderDataList', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          this.OrderListData = res.list
          this.total = res.total
        }
      })
    },
    DetailOrder (row) {
      this.mediaDialogVisible = true
      this.title = '订单详情'
      this.OrderDetailId=row.order_id
      this.OrderName=row.order_name

    },
    UpdatePublish () {
      var list = this.$refs.orderTable.selection
      console.log(list)
      if (list.length > 0) {
        this.publisForm.order_id = []
        list.forEach(a => {
          this.publisForm.order_id.push(a.order_id)
        })

        if (this.SalePerDataList.length === 0) {
          var User=util.JesToken()
          util.Get('user/getuserdatalist?userType=3&id='+User.user_sp_id).then(res=>{
            if (res.rpStatus === 10000) {
                this.SalePerDataList = res.list
            } else {
                this.$message.error(res.rpMsg)
            }
          })
        }
        this.dialogVisible = true
        if (this.searchForm.orderState === '0' || this.searchForm.orderState === '1') {
          this.title = '上刊'
          if (this.ProdCompanyDataList.length === 0) {
            util.Get('publish/getprodcompany').then(res => {
              if (res.rpStatus === 10000) {
                this.ProdCompanyDataList = res.list
              }
            })
          }
        } else {
          this.title = '下刊'
        }
      } else {
        this.$message.error('请选择订单')
      }
    },
    Save () {
      this.publisForm.state = 1
      if (this.searchForm.orderState === '0' || this.searchForm.orderState === '1') {
        this.publisForm.publish_pic_list = this.$refs.childRules.ReturnPicData()
        this.publisForm.state = 0
      }
      util.Post('publish/publish', this.publisForm).then(res => {
        if (res.rpStatus === 10000) {
          this.dialogVisible = false
          this.GetOrderDataList()
        }
      })
    },

    // 制作公司
    AddProdCompany () {
      this.companyDialogVisible = true
      this.pctitle = '合作商'
      this.companyForm.pc_name = ''
      this.companyForm.pc_remark = ''
    },
    Ok () {
      this.$refs.companyForm.validate((valid) => {
        if (valid) {
          util.Post('publish/addProdCompany', this.companyForm).then(res => {
            if (res.rpStatus === 10000) {
              var form = {
                pc_id: parseInt(res.rpMsg),
                pc_name: this.companyForm.pc_name
              }
              this.$set(this.ProdCompanyDataList, this.ProdCompanyDataList.length, form)
              this.$forceUpdate()
              this.companyDialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },


   // 导出
   async createExportData () {
     this.searchForm.pageNo=0
     var res=await util.Get('publish/getApplyOrderDataList', this.searchForm)
     if(res.rpStatus===10000){
        this.exportData = res.list
        return this.exportData
     }
    },
    finishDownload () {
      this.$message({
        message: '导出数据成功',
        type: 'success'
      })
      this.exportData=[]
    }
  }
}
</script>

<style scoped>
.tool{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
